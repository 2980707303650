import React from "react";

const Home = React.lazy(() => import("../../pages/Home"));
const Admin = React.lazy(() => import("../../pages/Admin/Admin"));
const AdminAuth = React.lazy(() => import("../../pages/Admin/AdminAuth"));
const AdminScanner = React.lazy(() => import("../../pages/Admin/AdminScanner"));
const TicketFind = React.lazy(() => import("../../pages/Admin/TicketFind"));

export interface IRoute {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

export enum RouteNames {
  HOME = '/',
  ADMIN = '/admin',
  ADMIN_AUTH = '/admin/auth',
  ADMIN_PAGE = '/admin/page/:page',
  ADMIN_SCANNER = '/admin/scanner',
  TICKET_FIND = '/ticket/:id'
}

export const publicRoutes: IRoute[ ] = [
  { path: RouteNames.HOME, exact: true, component: <Home /> },
  { path: RouteNames.ADMIN, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_AUTH, exact: true, component: <AdminAuth /> },
  { path: RouteNames.ADMIN_PAGE, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_SCANNER, exact: true, component: <AdminScanner /> },
  { path: RouteNames.TICKET_FIND, exact: true, component: <TicketFind /> },
]

export const twRoutes: IRoute[ ] = []
