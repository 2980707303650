import { FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import AppRouter from './components/AppRouter';
import {Context} from '.';
import useWindowDimensions from './core/hooks/useWindowDimensions';

import './styles/App.scss';

const App: FC = () => {

  const { store } = useContext(Context);
  
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    console.log(width);
    if (width <= 960) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }
  }, [width, height]);

  useEffect(() => {
    if (width <= 960) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }

    setTimeout(() => {
      store.setIsFaderShown(false);
      setTimeout(() => {
        store.setIsFaderVisible(false);
      }, 400);
    }, 1300);
  }, []);


  return (
    <>
      <div className="fader" style={{display: store.isFaderVisible ? "block" : "none", opacity: store.isFaderShown ? "1" : "0"}}></div>
        
      <AppRouter />
    </>
  )
}

export default observer(App)
