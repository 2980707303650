import { makeAutoObservable } from "mobx";
import { LOCALES } from "../i18n/locales";
import {HomeMenuPage} from "../utils/defaults";

export default class Store {

  isAuth = false;
  token: string = "";
  userData: any = {};
  locale: string = LOCALES.ENGLISH;

  isActiveA: boolean = false;
  portfolioRendered: boolean = false;

  isBackCallVisible: boolean = false;
  isMobile: boolean = false;
  isMenuOpenned: boolean = false;
  isFaderVisible: boolean = true;
  isFaderShown: boolean = true;
  isContentShown: boolean = false;
  isHomeBlockVisible: boolean = false;

  isHeaderLocked: boolean = false;
  selectedSlide: number = 0;

  menuSelected: number = 0;

  refuseDrop: boolean = false;

  c1Count: string = "0";
  c2Count: string = "0";
  c3Count: string = "0";
  c4Count: string = "0";

  constructor() {
    makeAutoObservable(this);
  }

  setC1Count(bl: string) {
    this.c1Count = bl;
  }
  setC2Count(bl: string) {
    this.c2Count = bl;
  }
  setC3Count(bl: string) {
    this.c3Count = bl;
  }
  setC4Count(bl: string) {
    this.c4Count = bl;
  }

  setRefuseDrop(bl: boolean) {
    this.refuseDrop = bl;
  }

  setSelectedSlide(bl: number) {
    this.selectedSlide = bl;
  }

  setIsHomeBlockVisible(bl: boolean) {
    this.isHomeBlockVisible = bl;
  }

  setMenuSelected(item: number) {
    this.menuSelected = item;
  }

  setIsHeaderLocked(bl: boolean) {
    this.isHeaderLocked = bl;
  }

  setIsBackCallVisible(bl: boolean) {
    this.isBackCallVisible = bl;
  }

  setIsContentShown(bl: boolean) {
    this.isContentShown = bl;
  }

  setIsMobile(bl: boolean) {
    this.isMobile = bl;
  }

  setIsMenuOpenned(bl: boolean) {
    this.isMenuOpenned = bl;
  }

  setIsFaderVisible(bl: boolean) {
    this.isFaderVisible = bl;
  }

  setIsFaderShown(bl: boolean) {
    this.isFaderShown = bl;
  }

}
